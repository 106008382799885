import React, {Component} from 'react'
import PropTypes from 'prop-types';
import axios from "axios";
import config from "./config";
import {Link} from "react-router-dom";
import ReactTable from "react-table-v6";


export default class Transactions extends Component {

  state = {
    data: [],
    pages: null,
    loading: true
  };

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {

      let orderBy = "id";
      let orderDesc = true;
      if (sorted.length > 0) {
        orderBy = sorted[0].id;
        orderDesc = sorted[0].desc;
      }

      let filters = {};
      if (filtered.length > 0)
        filtered.forEach(function (filter) {
          filters[filter.id] = filter.value;
        });

      return axios.get(config.apiHost + `partner/` + this.props.partner + `/transactions`, {
        params: {
          "orderBy": orderBy,
          "orderDesc": orderDesc,
          "pageSize": pageSize,
          "filter": JSON.stringify(filters),
          "page": page
        }
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
    });
  }

  render() {
    const columns = [
      {
        Header: 'Id',
        accessor: 'id',
        maxWidth: 80
      },
      {
        Header: 'Date',
        accessor: 'datetime',
        maxWidth: 150
      },
      {
        Header: 'booking',
        accessor: 'bookingId',
        maxWidth: 130,
        Cell: (row) => (
          <span>
            <Link to={"/booking/" + row.row['bookingId']}>{row.value}</Link>
          </span>
        )
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        maxWidth: 100
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        maxWidth: 70
      },
      {
        Header: 'Type',
        accessor: 'type',
        maxWidth: 120
      },
      {
        Header: 'Comment',
        accessor: 'comment'
      },
      {
        Header: 'Test',
        accessor: 'test'
      }
    ];

    const { data, pages, loading } = this.state;

    return (
      <div>
        <ReactTable
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData.bind(this)} // Request new data when things change
          filterable
          defaultPageSize={100}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

Transactions.propTypes = {
  partner: PropTypes.string
};