import '../css/suppliers.scss';

import React from 'react';
import Page from "./page";
import axios from "axios";
import config from "../components/config";
import { Button, Checkbox, Container, Segment, Table } from "semantic-ui-react";
import { Controlled as CodeMirror } from "react-codemirror2";

class SuppliersPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      search: [],
      validation: [],
      suppliers:[],
      loading: true,
      scriptSaving: false,
      script: ''
    };
  }

  requestData() {
    return new Promise((resolve, reject) => {
      return axios.get(config.apiHost + `suppliers`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }

  fetchData() {
    this.setState({ loading: true });

    this.requestData()
      .then(res => {
        this.setState({
          search: res.search,
          validation: res.validation,
          cache: res.cache,
          suppliers: res.suppliers,
          loading: false,
          script: res.script
        });
      })
      .catch(error => alert(error.message));
  }

  changeSearch(name) {
    let stateBefore = this.state.search[name];

    return axios.get(config.apiHost + `suppliers/set`, {
      params: {
        "name": name,
        "search": !stateBefore
      }
    })
      .then(() => {
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.fetchData();
      });
  }

  changeValidation(name) {
    let stateBefore = this.state.validation[name];

    return axios.get(config.apiHost + `suppliers/set`, {
      params: {
        "name": name,
        "validation": !stateBefore
      }
    })
      .then(() => {
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.fetchData();
      });
  }

  changeCache(name) {
    let stateBefore = this.state.cache[name];

    return axios.get(config.apiHost + `suppliers/set`, {
      params: {
        "name": name,
        "cache": !stateBefore
      }
    })
      .then(() => {
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.fetchData();
      });
  }

  renderAuth() {
    const { search, validation, cache, loading, suppliers } = this.state;

    if (loading) {
      return <div>
        <Container textAlign='center'>
          <Segment loading><br/><br/></Segment>
        </Container>
      </div>
    }

    return <div>
      {this.renderScript()}
      <hr/>
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Search</Table.HeaderCell>
            <Table.HeaderCell>Validation</Table.HeaderCell>
            <Table.HeaderCell>Cache</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            Object.keys(search).map(name => {
              return <Table.Row verticalAlign='top' negative={!search[name] || !validation[name]}>
                <Table.Cell className="check">
                  <Checkbox label='' onChange={this.changeSearch.bind(this, name)} checked={search[name]}/>
                </Table.Cell>
                <Table.Cell className="check">
                  {name === "cache" ? [] :
                    <Checkbox label='' onChange={this.changeValidation.bind(this, name)} checked={validation[name]}/>
                  }
                </Table.Cell>
                <Table.Cell className="check">
                  {cache[name] === null ? [] :
                    <Checkbox label='' onChange={this.changeCache.bind(this, name)} checked={cache[name]}/>
                  }
                </Table.Cell>
                <Table.Cell>
                  {name in suppliers ? <div><b>{name}</b><br/><code>{suppliers[name].type}<br/>{suppliers[name].proxy}</code></div> : <div><b>{name}</b></div>}
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  saveScript() {
    this.setState({ scriptSaving: true });
    return axios.post(
      config.apiHost + `suppliers/set-script`,
      this.state.script
    )
      .then(response => {
        this.setState({ scriptSaving: false });

        if (response.data.error !== "")
          alert(response.data.error);
        else
          this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.setState({ scriptSaving: false });
      });
  }

  renderScript() {
    if (this.state.scriptSaving)
      return <Segment loading/>;

    return <div>
      <Button size='small' primary onClick={this.saveScript.bind(this)}>Save</Button><br/><br/>
      <code>
        <b>isRt:</b> Boolean<br/>
        <b>provider:</b> String<br/>
        <b>adults:</b> Int<br/>
        <b>passengers:</b> Int<br/>
        <b>firstAirline:</b> String<br/>
        <b>daysToFlights:</b> Int<br/>
        <b>price:</b> java.math.BigDecimal
      </code>
      <CodeMirror
        value={this.state.script}
        onBeforeChange={(editor, data, value) => {
          this.setState({ script: value });
        }}
        options={{
          mode: 'text/x-kotlin',
          matchBrackets: true
        }}
      />
    </div>
  }

  componentDidMount() {
    this.fetchData();
  }

}

export default SuppliersPage;
