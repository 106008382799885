import '../css/partner.scss';
import 'codemirror/lib/codemirror.css';

import React from 'react';
import Page from "./page";
import 'react-table-v6/react-table.css';
import axios from 'axios';
import qs from "qs";
import {Controlled as CodeMirror} from 'react-codemirror2';

import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Modal,
  Segment,
  Tab,
  Table
} from "semantic-ui-react";
import config from '../components/config';
import Transactions from "../components/transactions";
import Utils from '../components/utils';

require('codemirror/mode/clike/clike');

class PartnerPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      data: null,
      logsOpen: false,
      modalOpen: false,
      depositAmount: '',
      depositComment: '',
      usersLoading: false,
      scriptSaving: false,
      script: '',
      globalScript: ''
    };
    this.loadData()
  }

  loadData() {
    return axios.get(config.apiHost + `partner/` + this.getPartner())
      .then(response => {
        if (response.data.error !== "")
          return this.setState({ error: response.data.error, loading: false, data: null });

        return this.setState({
          error: null,
          loading: false,
          data: response.data.data,
          script: response.data.data.script,
          globalScript: response.data.data.globalScript
        });
      })
      .catch(error => this.setState({ error: error.message }));
  }

  fetchData() {
    this.setState({ loading: true });
    return this.loadData();
  }

  getPartner() {
    return this.props.match.params.partner;
  }

  renderBreadcrumbs() {
    return <Container className="breadcrumbs">
      <Breadcrumb>
        <Breadcrumb.Section>Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section>Partners</Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section active>{this.getPartner()}</Breadcrumb.Section>
      </Breadcrumb>
    </Container>
  }

  addDeposit() {
    let depo = this.state.depositAmount;
    let comment = this.state.depositComment;

    if (depo === 0 || depo === '') {
      alert("No deposit amount set");
      return;
    }

    if (comment === '') {
      alert("No deposit comment set");
      return;
    }

    this.setState({ loading: true });

    return axios.get(config.apiHost + `partner/` + this.getPartner() + `/deposit`, {
      params: {
        "amount": depo,
        "comment": comment
      }
    })
      .then(() => {
        this.loadData();
        alert("Deposit added");
      })
      .catch(error => {
        alert(error.message);
        this.loadData();
      });
  }

  renderAuth() {
    if (this.state.error != null)
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment>
            <Icon color='red' name='exclamation triangle' size='huge'/><br/><br/>
            <strong>{this.state.error}</strong>
          </Segment>
        </Container>
      </div>;

    if (this.state.loading) {
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment loading><br/><br/></Segment>
        </Container>
      </div>
    }

    return <div>
      {this.renderBreadcrumbs()}
      <Container>
        <Header as='h1'>{this.state.data.name}</Header>
        <div>
          <Modal trigger={<Button>Add deposit</Button>} closeIcon>
            <Header content='Add deposit'/>
            <Modal.Content>
              <Form>
                <Form.Field key="amount">
                  <label>Amount (EUR)</label>
                  <input placeholder='100'
                         value={this.state.depositAmount}
                         onChange={e => this.setState({ depositAmount: e.target.value })}/>
                </Form.Field>
                <Form.Field key="description">
                  <label>Description</label>
                  <input placeholder='Deposit from ...'
                         value={this.state.depositComment}
                         onChange={e => this.setState({ depositComment: e.target.value })}/>
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red'>
                <Icon name='remove'/> Cancel
              </Button>
              <Button color='green' onClick={this.addDeposit.bind(this)}>
                <Icon name='checkmark'/> Add deposit
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
        <Divider/>

        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={10}>
              {this.renderMainInfo()}
            </Grid.Column>
            <Grid.Column width={4}>
              <Card>
                <Card.Content>
                  <Card.Header>Current balance</Card.Header>
                  <Card.Description>{Utils.formatNumber(this.state.data.balance)} {this.state.data.currency}</Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderTabs()}

        <Modal open={this.state.modalOpen} basic size='small'>
          <Modal.Content>
            <Loader/>
            <Header as='h3' textAlign='center' inverted className="loaderHeader">
              Loading...
            </Header>
          </Modal.Content>
        </Modal>
      </Container>
      <br/>
    </div>
  }

  showLoader() {
    this.setState({ modalOpen: true });
  }

  hideLoader() {
    this.setState({ modalOpen: false });
  }

  renderTabs() {
    let panes = [
      { menuItem: 'Suppliers', key: 's', render: () => this.renderSuppliers() },
      { menuItem: 'Transactions', key: 't', render: () => this.renderTransactions() },
      { menuItem: 'Users', key: 'u', render: () => this.renderUsers() },
      { menuItem: 'Markup', key: 'm', render: () => this.renderMarkup() },
    ];

    return <div style={{ marginTop: '15px' }}>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
    </div>;
  }

  changeSearch(name) {
    let stateBefore = this.state.data.suppliers.search[name];

    return axios.get(config.apiHost + `suppliers/set`, {
      params: {
        "name": name,
        "partner": this.getPartner(),
        "search": !stateBefore
      }
    })
      .then(() => {
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.fetchData();
      });
  }

  changeValidation(name) {
    let stateBefore = this.state.data.suppliers.validation[name];

    return axios.get(config.apiHost + `suppliers/set`, {
      params: {
        "name": name,
        "partner": this.getPartner(),
        "validation": !stateBefore
      }
    })
      .then(() => {
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.fetchData();
      });
  }

  changeCache(name) {
    let stateBefore = this.state.data.suppliers.cache[name];

    return axios.get(config.apiHost + `suppliers/set`, {
      params: {
        "name": name,
        "partner": this.getPartner(),
        "cache": !stateBefore
      }
    })
      .then(() => {
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.fetchData();
      });
  }

  triggerUser(name, data) {
    this.setState({ usersLoading: true });
    return axios.post(
      config.apiHost + `partner/` + this.getPartner() + `/users/` + name + `/change`,
      qs.stringify(data)
    )
      .then(() => {
        this.setState({ usersLoading: false });
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.setState({ usersLoading: false });
        this.fetchData();
      });
  }

  changePass(username) {
    let newPassword = window.prompt("New password:", "");
    if (newPassword === null || newPassword === "")
      return;

    this.triggerUser(username, { password: newPassword });
  }

  createUser() {
    let username = window.prompt("Username:", "");
    if (username === null || username === "")
      return;

    let password = window.prompt("Password:", "");
    if (password === null || password === "")
      return;

    this.setState({ usersLoading: true });

    return axios.post(
      config.apiHost + `partner/` + this.getPartner() + `/users/add`,
      qs.stringify({
        username: username,
        password: password
      })
    )
      .then(() => {
        this.setState({ usersLoading: false });
        this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.setState({ usersLoading: false });
        this.fetchData();
      });
  }

  renderUsers() {
    if (this.state.usersLoading)
      return <Segment loading/>;

    return <div>
      <Button icon='plus' size='small' primary onClick={this.createUser.bind(this)}>Add user</Button><br/><br/>
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Enabled</Table.HeaderCell>
            <Table.HeaderCell>Login</Table.HeaderCell>
            <Table.HeaderCell>Created at</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.users.map(user => {
              return <Table.Row verticalAlign='top' negative={!user.enabled}>
                <Table.Cell className="check">
                  <Checkbox label='' onChange={this.triggerUser.bind(this, user.login,
                    { "enabled": !user.enabled }
                  )} checked={user.enabled}/>
                </Table.Cell>
                <Table.Cell>{user.login}</Table.Cell>
                <Table.Cell>{user.createdAt}</Table.Cell>
                <Table.Cell><Button size='tiny' onClick={this.changePass.bind(this, user.login)}>Change
                                                                                                 password</Button></Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  saveScript() {
    this.setState({ scriptSaving: true });
    return axios.post(
      config.apiHost + `partner/` + this.getPartner() + `/script`,
      this.state.script
    )
      .then(response => {
        this.setState({ scriptSaving: false });

        if (response.data.error !== "")
          alert(response.data.error); else
          this.fetchData();
      })
      .catch(error => {
        alert(error.message);
        this.setState({ scriptSaving: false });
      });
  }

  renderMarkup() {
    if (this.state.scriptSaving)
      return <Segment loading/>;

    return <div>
      <Button size='small' primary onClick={this.saveScript.bind(this)}>Save</Button><br/><br/>
      <code>
        <b>isRt:</b> Boolean<br/>
        <b>provider:</b> String<br/>
        <b>adults:</b> Int<br/>
        <b>passengers:</b> Int<br/>
        <b>firstAirline:</b> String<br/>
        <b>daysToFlights:</b> Int<br/>
        <b>price:</b> java.math.BigDecimal
      </code>
      <pre>{this.state.globalScript}</pre>
      <CodeMirror
        value={this.state.script}
        onBeforeChange={(editor, data, value) => {
          this.setState({ script: value });
        }}
        options={{
          mode: 'text/x-kotlin',
          matchBrackets: true
        }}
      />
    </div>
  }

  renderSuppliers() {
    const { search, validation, cache } = this.state.data.suppliers;

    return <Table celled attached unstackable compact size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Search</Table.HeaderCell>
          <Table.HeaderCell>Validation</Table.HeaderCell>
          <Table.HeaderCell>Cache</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          Object.keys(search).map(name => {
            return <Table.Row verticalAlign='top' negative={!search[name] || !validation[name]}>
              <Table.Cell className="check">
                <Checkbox label='' onChange={this.changeSearch.bind(this, name)} checked={search[name]}/>
              </Table.Cell>
              <Table.Cell className="check">
                {name === "cache" ? [] :
                  <Checkbox label='' onChange={this.changeValidation.bind(this, name)} checked={validation[name]}/>
                }
              </Table.Cell>
              <Table.Cell className="check">
                {cache[name] === null ? [] :
                  <Checkbox label='' onChange={this.changeCache.bind(this, name)} checked={cache[name]}/>
                }
              </Table.Cell>
              <Table.Cell>{name}</Table.Cell>
            </Table.Row>
          })
        }
      </Table.Body>
    </Table>
  }

  renderTransactions() {
    return <Transactions partner={this.state.data.name}/>
  }

  renderMainInfo() {
    return <div>
      <Message
        size="tiny"
        attached
        className="bg-light-yellow"
        header='Main info'
      />
      <Table compact attached unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="name">Id</Table.Cell>
            <Table.Cell>{this.state.data.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Name</Table.Cell>
            <Table.Cell>{this.state.data.name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Created at</Table.Cell>
            <Table.Cell>{this.state.data.createdAt}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Email</Table.Cell>
            <Table.Cell>
              <small>{this.state.data.email}</small>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Webhook</Table.Cell>
            <Table.Cell>{this.state.data.webhook}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Currency</Table.Cell>
            <Table.Cell>{this.state.data.currency}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  }

}


export default PartnerPage;
