import '../css/bookings.scss';

import React from 'react';
import Page from "./page";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css';
import axios from 'axios';
import {Link} from "react-router-dom";
import config from '../components/config'
import Utils from '../components/utils'
import {Button, Divider, Form, Header, Icon, Modal} from "semantic-ui-react";

class PartnersPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      partnerLogin: "",
      partnerPassword: "",
      partnerEmail: ""
    };
    this.fetchData = this.fetchData.bind(this);
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {

      // You can use the filters in your request, but you are responsible for applying them.
      // if (filtered.length) {
      //   filteredData = filtered.reduce((filteredSoFar, nextFilter) => {
      //     return filteredSoFar.filter(row => {
      //       return ( row[nextFilter.id] + "" ).includes(nextFilter.value);
      //     });
      //   }, filteredData);
      // }

      let orderBy = "id";
      let orderDesc = true;
      if (sorted.length > 0) {
        orderBy = sorted[0].id;
        orderDesc = sorted[0].desc;
      }

      let filters = {};
      if (filtered.length > 0)
        filtered.forEach(function (filter) {
          filters[filter.id] = filter.value;
        });

      return axios.get(config.apiHost + `partners`, {
        params: {
          "orderBy": orderBy,
          "orderDesc": orderDesc,
          "pageSize": pageSize,
          "filter": JSON.stringify(filters),
          "page": page
        }
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }

  fetchData(state = this.state) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
    });
  }

  addPartner() {
    let login = this.state.partnerLogin;
    let password = this.state.partnerPassword;

    if (login === 0 || login === '') {
      alert("No login set");
      return;
    }

    if (password === '') {
      alert("No password set");
      return;
    }

    this.setState({ loading: true });

    return axios.get(config.apiHost + `partner/add`, {
      params: {
        "email": this.state.partnerEmail,
        "login": login,
        "password": password
      }
    })
      .then(() => {
        this.fetchData();
        alert("Partner added");
      })
      .catch(error => {
        alert(error.message);
        this.fetchData();
      });
  }

  renderAuth() {
    const columns = [
      {
        Header: 'Id',
        accessor: 'id',
        maxWidth: 80
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (row) => (
          <span>
            <Link to={"/partner/" + row.value}>{row.value}</Link>
          </span>
        )
      },
      {
        Header: 'Email',
        accessor: 'email',
        maxWidth: 200
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        maxWidth: 80
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        maxWidth: 150,
        Cell: row => (
          <span>
            {Utils.formatNumber(row.value.toFixed(2))}
          </span>
        )
      },
      {
        Header: 'Status',
        accessor: 'enabled',
        maxWidth: 100,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === '0' ? '#ff2e00' : '#57d500',
              transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {row.value === '0' ? 'Disabled' : 'Enabled'}
          </span>
        )
      }
    ];

    const { data, pages, loading } = this.state;
    return (
      <div>
        <div>
          <Modal trigger={<Button>Add</Button>} closeIcon>
            <Header content='Add partner'/>
            <Modal.Content>
              <Form>
                <Form.Field key="login">
                  <label>Login</label>
                  <input placeholder='login'
                         value={this.state.partnerLogin}
                         onChange={e => this.setState({ partnerLogin: e.target.value })}/>
                </Form.Field>
                <Form.Field key="password">
                  <label>Password</label>
                  <input value={this.state.partnerPassword}
                         onChange={e => this.setState({ partnerPassword: e.target.value })}/>
                </Form.Field>
                <Form.Field key="email">
                  <label>Email</label>
                  <input value={this.state.partnerEmail}
                         onChange={e => this.setState({ partnerEmail: e.target.value })}/>
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red'>
                <Icon name='remove'/> Cancel
              </Button>
              <Button color='green' onClick={this.addPartner.bind(this)}>
                <Icon name='checkmark'/> Add partner
              </Button>
            </Modal.Actions>
          </Modal>
          <Divider/>
        </div>
        <ReactTable
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          filterable
          defaultPageSize={100}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default PartnersPage;
