import '../css/bookings.scss';

import React from 'react';
import Page from "./page";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css';
import axios from 'axios';
import config from '../components/config'

class BalancesPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true
    };
    this.fetchData = this.fetchData.bind(this);
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      return axios.get(config.apiHost + `suppliers/balances`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }

  fetchData(state = this.state) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData( ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.balances,
        pages: 1,
        loading: false
      });
    });
  }

  renderAuth() {
    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 150
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        maxWidth: 150
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        maxWidth: 350
      },
      {
        Header: 'Date',
        accessor: 'date',
        maxWidth: 250
      }
    ];

    const { data, pages, loading } = this.state;
    return (
      <div>
        <ReactTable
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          filterable
          defaultPageSize={100}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default BalancesPage;
