import '../css/refunds.scss';

import React from 'react';
import Page from "./page";
import axios from "axios";
import config from "../components/config";
import ReactTable from "react-table-v6";
import {Button, Checkbox, Icon, Loader, Modal} from "semantic-ui-react";
import {logout} from "../utils/auth";

class RefundsPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      modalOpen: false,
      logText: null
    };
    this.fetchData = this.fetchData.bind(this);
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      let orderBy = "id";
      let orderDesc = true;
      if (sorted && sorted.length > 0) {
        orderBy = sorted[0].id;
        orderDesc = sorted[0].desc;
      }

      let filters = {};
      if (filtered && filtered.length > 0)
        filtered.forEach(function (filter) {
          filters[filter.id] = filter.value;
        });

      return axios.get(config.apiHost + `refunds/list`, {
        params: {
          "orderBy": orderBy,
          "orderDesc": orderDesc,
          "pageSize": pageSize,
          "filter": JSON.stringify(filters),
          "page": page
        }
      })
      .then(response => resolve(response.data))
      .catch(error => {
        if (error.response.status === 401) {
          logout();
        }

        return reject(error);
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({loading: true});

    state = state || {
      pageSize: 100,
      page: 0,
      sorted: null,
      filtered: null
    };

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(
      state.pageSize || 100,
      state.page || 0,
      state.sorted || null,
      state.filtered || null
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.data,
        pages: 1,
        loading: false
      });
    });
  }

  change(id, name, checked) {
    this.setState({loading: true});
    let self = this;

    return axios.get(
      config.apiHost + `refunds/change`,
      {
        params: {
          "id": id,
          "name": name,
          "value": checked
        }
      }
    )
    .then(() => {
      self.fetchData();
    })
    .catch(error => {
      alert(error.message);
      self.fetchData();
    });
  }

  changeSwitch(name, stateBefore, type) {
    let params = {"name": name.replace("cache.", "")};
    params[type] = !stateBefore;

    return axios.get(config.apiHost + `suppliers/set`, {
      params: params
    })
    .then(() => {
      this.fetchData();
    })
    .catch(error => {
      alert(error.message);
      this.fetchData();
    });
  }

  getClassForRow(data) {
    if (data["customerRequested"] && !data["refundRequested"]) {
      return "request-refund";
    }

    if (data["refundReceived"])
      if (!data["refundMade"] && data["customerRequested"])
        return "refund-to-pay";

    return "";
  }

  renderAuth() {
    const columns = [
      {
        Header: 'Id',
        accessor: 'id',
        maxWidth: 60
      },
      {
        Header: 'Booking id',
        accessor: 'bookingId',
        maxWidth: 70,
        Cell: (row) => (
          <span>
            <a href={"/booking/" + row.value} target="_blank">{row.value}</a>
          </span>
        )
      },
      {
        Header: 'JetCompass',
        accessor: 'partnerId',
        maxWidth: 70,
        Cell: (row) => (
          <span>
            <a href={"https://admin.jetcompass.com/site/search?q=" + row.value} target="_blank">{row.value}</a>
          </span>
        )
      },
      {
        Header: 'Provider',
        accessor: 'provider',
        maxWidth: 80
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        maxWidth: 80
      },
      {
        Header: 'Email sent',
        accessor: 'emailSent',
        maxWidth: 80,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value}
                      onChange={this.change.bind(this, row.original['id'], "emailSent", !row.value)}/>
          </div>
        )
      },
      {
        Header: 'Customer requested',
        accessor: 'customerRequested',
        maxWidth: 80,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value}
                      onChange={this.change.bind(this, row.original['id'], "customerRequested", !row.value)}/>
          </div>
        )
      },
      {
        Header: 'Self requested',
        accessor: 'selfRequested',
        maxWidth: 80,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value}
                      onChange={this.change.bind(this, row.original['id'], "selfRequested", !row.value)}/>
          </div>
        )
      },
      {
        Header: 'Refund requested',
        accessor: 'refundRequested',
        maxWidth: 80,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value}
                      onChange={this.change.bind(this, row.original['id'], "refundRequested", !row.value)}/>
          </div>
        )
      },
      {
        Header: 'Refund received',
        accessor: 'refundReceived',
        maxWidth: 80,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value}
                      onChange={this.change.bind(this, row.original['id'], "refundReceived", !row.value)}/>
          </div>
        )
      },
      {
        Header: 'Refund made',
        accessor: 'refundMade',
        maxWidth: 80,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value}
                      onChange={this.change.bind(this, row.original['id'], "refundMade", !row.value)}/>
          </div>
        )
      },
      {
        Header: 'Alt refund',
        accessor: 'altRefund',
        maxWidth: 80,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value}
                      onChange={this.change.bind(this, row.original['id'], "altRefund", !row.value)}/>
          </div>
        )
      },
      {
        Header: 'Comment',
        accessor: 'comment'
      }
    ];

    const {data, pages, loading} = this.state;

    return (
      <div>
        <ReactTable
          getTrProps={(state, rowInfo, column) => {
            try {
              return {
                className: this.getClassForRow(rowInfo.row)
              };
            } catch (e) {
              return "";
            }
          }}
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          filterable
          defaultPageSize={100}
          className="-highlight"
        />
        <Modal
          closeIcon={this.state.logText != null}
          open={this.state.modalOpen}
          basic={this.state.logText == null}
          onClose={() => this.setState({modalOpen: false})}
          size='small'
        >
          <Modal.Content>
            {this.state.logText ? <textarea>{this.state.logText}</textarea> : <Loader/>}
          </Modal.Content>
          {this.state.logText ? <Modal.Actions>
            <Button primary onClick={() => this.setState({modalOpen: false})}>
              <Icon name='remove'/> Close
            </Button>
          </Modal.Actions> : []
          }
        </Modal>
      </div>
    )
  }

  percentColors = [
    {pct: 0, color: {r: 0xff, g: 0x00, b: 0}},
    {pct: 70, color: {r: 0xff, g: 0x20, b: 0}},
    {pct: 80, color: {r: 0x80, g: 0x20, b: 0}},
    {pct: 80, color: {r: 0xa0, g: 0x80, b: 0}},
    {pct: 100, color: {r: 0x00, g: 0xff, b: 0}}
  ];

  getColorForPercentage(pct) {
    let i = 1;
    while (i < this.percentColors.length - 1) {
      if (pct < this.percentColors[i].pct)
        break;
      i++
    }
    const lower = this.percentColors[i - 1];
    const upper = this.percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }

}

export default RefundsPage;
