import React from "react";
import {Label} from "semantic-ui-react";

export function formatLabel(label) {
  let color = 'grey';

  switch (label) {
    case "issued":
      color = 'green';
      break;
    case "waiting":
    case "unknown":
      color = 'yellow';
      break;
    case "failed":
    case "totally_failed":
      color = 'red';
      break;

    case "completed":
      color = 'green';
      break;
    case "started":
      color = "yellow";
      break;
    case "error":
    case "dead":
      color = "red";
      break;

    //Cards
    case "valid":
      color = "green";
      break;
    case "cancelled":
      color = "red";
      break;

    //Transactions
    case "authorized":
    case "charged":
      color = "green";
      break;
    case "declined":
      color = "red";
      break;
  }

  return <Label color={color} key={color}>{label}</Label>
}