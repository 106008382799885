import './css/style.scss';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './routes';
import React, {Component} from 'react';
import {checkAuth} from "./utils/auth";

class App extends Component {

  constructor(props) {
    super(props);
    checkAuth();
  }

  pageChange(url) {
    // ym('hit', url);
    // ReactGA.pageview(url);
    // this.siftPageChange();
  }

  render() {
    return (
      <Router>
        <div className="fullContainer">
          <div className="bodyContainer">
            <Routes/>
          </div>
          {/*<Footer/>*/}
        </div>
      </Router>
    )
  }
}

App.propTypes = {};

App.defaultProps = {
  options: {},
};

export default App;