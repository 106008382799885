class Config {

  apiHost = Config.isDev()
    ? 'http://localhost:8081/admin/'
    : 'https://api.farecloud.io/admin/';

  webHost = Config.isDev()
    ? 'http://localhost:4000/'
    : 'https://admin.farecloud.io/';

  dev = Config.isDev();

  static isDev(): boolean {
    return (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1' ||
      window.location.hostname === 'testapi.farecloud.io'
    );
  }
}

let config = new Config();
window.config = config;

export default config;