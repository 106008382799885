import config from '../components/config'
import axios from "axios";

const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';

export function login() {

}

export function loginGoogle() {
  if (config.dev)
    window.location = "http://testapi.farecloud.io:8081/admin-auth";
  else
    window.location = "https://api.farecloud.io/admin-auth";
}

export function getUserEmail() {
  return getIdToken();
}

export function logout() {
  clearIdToken();
  clearAccessToken();
  axios.defaults.headers.common['Authorization'] = null;
  window.location = "/login";
}

export function requireAuth(nextState, replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/' });
  }
}

export function getIdToken() {
  if (config.dev)
    return "dev";

  return localStorage.getItem(ID_TOKEN_KEY);
}

export function getAccessToken() {
  if (config.dev)
    return "dev";

  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

// Helper function that will allow us to extract the access_token and id_token
export function getParameterByName(name) {
  let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

// Get and store access_token in local storage
export function setTokens(accessToken, idToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(ID_TOKEN_KEY, idToken);
  axios.defaults.headers.common['Authorization'] = 'Basic ' + btoa(idToken + ':' + accessToken);
}

export function checkAuth() {
  if (isLoggedIn())
    axios.defaults.headers.common['Authorization'] = 'Basic ' + btoa(getIdToken() + ':' + getAccessToken());
}

// Get and store id_token in local storage
export function setIdToken(idToken) {

}

export function isLoggedIn() {
  const idToken = getIdToken();
  return !!idToken && idToken !== "null";
}