import React, { Component } from 'react'
import PropTypes from 'prop-types';
import axios from "axios";
import config from "./config";
import { Button, Dropdown } from "semantic-ui-react";

export default class AviasalesButton extends Component {

  state = {
    prices: {},
    loading: true,
    error: false
  };

  requestData() {
    return new Promise((resolve, reject) => {
      return axios.get(config.apiHost + `pnr/aviasales`, {
        params: {
          "id": this.props.pnrId
        }
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }

  fetchData() {
    this.setState({ loading: true });

    this.requestData()
      .then(res => {
        this.setState({
          prices: res.prices,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ error: true, loading: false });
      });
  }

  render() {
    if (this.state.loading)
      return <Button loading>Loading</Button>;

    if (this.state.error)
      return <Button negative disabled>Prices load error</Button>;

    if (Object.keys(this.state.prices).length === 0)
      return <Button>No options</Button>;

    return <Dropdown button text='Aviasales'>
      <Dropdown.Menu>
        {Object.keys(this.state.prices).map(name => {
          let o = this.state.prices[name];
          let text = o["name"] + " - " + o["price"];

          return <Dropdown.Item key={o["name"]} text={text} onClick={e => window.open(o["url"], '_blank')}/>
        })}
      </Dropdown.Menu>
    </Dropdown>
  }

  componentDidMount() {
    this.fetchData();
  }
}

AviasalesButton.propTypes = {
  pnrId: PropTypes.number
};