import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router-dom';
import LoginPage from "./pages/login";
import BookingsPage from "./pages/bookings";
import TasksPage from "./pages/tasks";
import BookingPage from "./pages/booking";
import SuppliersPage from "./pages/suppliers";
import PartnersPage from "./pages/partners";
import PartnerPage from "./pages/partner";
import BalancesPage from "./pages/balances";
import CachePage from "./pages/cache";
import RefundsPage from "./pages/refunds";

const Routes = props => (
  <main>
    <Switch {...props}>
      <Route path="/bookings" exact component={BookingsPage} />
      <Route path="/orders" exact component={BookingsPage} />
      <Route path="/partners" exact component={PartnersPage} />
      <Route path="/balances" exact component={BalancesPage} />
      <Route path="/tasks" exact component={TasksPage} />
      <Route path="/cache" exact component={CachePage} />
      <Route path="/refunds" exact component={RefundsPage} />
      <Route path="/suppliers" exact component={SuppliersPage} />
      <Route path="/booking/:id" component={BookingPage} />
      <Route path="/order/:id" component={BookingPage} />
      <Route path="/partner/:partner" component={PartnerPage} />
      <Route path="*" exact component={LoginPage} />
    </Switch>
  </main>
);

export default Routes;
