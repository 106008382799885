import '../css/login.scss';

import React from 'react';
import Page from "./page";
import {isLoggedIn, login, loginGoogle, setTokens} from "../utils/auth";
import {Redirect} from "react-router";
import {Button, Container, Segment} from "semantic-ui-react";
import cloud from '../assets/cloud.svg';

class LoginPage extends Page {

  constructor(props) {
    super(props);
  }

  render() {
    let url = new URL(window.location.href);

    if (url.searchParams.get('token')) {
      setTokens(url.searchParams.get('token'), url.searchParams.get('email'));

      return <Redirect to="/bookings"/>;
    }

    if (isLoggedIn())
      return <Redirect to="/bookings"/>;

    return <div className="login-page">
      <Segment vertical>
        <Container textAlign='center'>
          <img src={cloud} width={70} alt="Farecloud"/>
          <h3 style={{ marginTop: "5px" }}>Farecloud</h3>
          <Button size='medium' primary className="google" onClick={loginGoogle}>Sign in with Google</Button><br/>
          <a href="#" onClick={login}>Login</a>
        </Container>
      </Segment>
    </div>
  }
}

export default LoginPage;
