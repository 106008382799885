import React, {Component} from 'react'
import {Menu} from 'semantic-ui-react'
import {getUserEmail, logout} from "../utils/auth";
import cloud from "../assets/white-cloud.svg";

export default class TopMenu extends Component {

  state = {
    activeItem: this.props.history.location.pathname.substring(1),
  };

  handleItemClick = (e, {name}) => {
    this.props.history.push("/" + name);
  };

  render() {
    const {activeItem} = this.state;

    return (
      <Menu
        fixed="top"
        inverted={true}
        className="top-menu"
        size="small"
        stackable={true}
      >
        <div className="ui container">
          <Menu.Item className="header">
            <div>
              <img src={cloud} width={20} alt="Cloud"/>
              <span>Farecloud</span>
            </div>
          </Menu.Item>

          <Menu.Item
            name='bookings'
            active={activeItem === 'bookings'}
            onClick={this.handleItemClick}
          >
            Bookings
          </Menu.Item>

          <Menu.Item
            name='suppliers'
            active={activeItem === 'suppliers'}
            onClick={this.handleItemClick}
          >
            Suppliers
          </Menu.Item>

          <Menu.Item
            name='partners'
            active={activeItem === 'partners'}
            onClick={this.handleItemClick}
          >
            Partners
          </Menu.Item>

          <Menu.Item
            name='refunds'
            active={activeItem === 'refunds'}
            onClick={this.handleItemClick}
          >
            Refunds
          </Menu.Item>

          <Menu.Item
            name='tasks'
            active={activeItem === 'tasks'}
            onClick={this.handleItemClick}
          >
            Tasks
          </Menu.Item>

          <Menu.Item
            name='cache'
            active={activeItem === 'cache'}
            onClick={this.handleItemClick}
          >
            Cache
          </Menu.Item>

          <Menu.Item
            name='balances'
            active={activeItem === 'balances'}
            onClick={this.handleItemClick}
          >
            Balances
          </Menu.Item>

          <Menu.Menu position='right'>
            <Menu.Item
              className="userEmail">
              {getUserEmail()}
            </Menu.Item>

            <Menu.Item onClick={logout}>
              Log out
            </Menu.Item>
          </Menu.Menu>
        </div>
      </Menu>
    )
  }
}