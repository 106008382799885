import '../css/tasks.scss';

import React from 'react';
import Page from "./page";
import axios from "axios";
import config from "../components/config";
import _ from "lodash";
import ReactTable from "react-table-v6";
import {formatLabel} from "../utils/utils";
import {Button, Checkbox, Icon, Label, Loader, Modal} from "semantic-ui-react";
import {logout} from "../utils/auth";

class CachePage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      proxies: [],
      data: [],
      pages: null,
      loading: true,
      modalOpen: false,
      logText: null
    };
    this.fetchData = this.fetchData.bind(this);
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      let orderBy = "id";
      let orderDesc = true;
      if (sorted && sorted.length > 0) {
        orderBy = sorted[0].id;
        orderDesc = sorted[0].desc;
      }

      let filters = {};
      if (filtered && filtered.length > 0)
        filtered.forEach(function (filter) {
          filters[filter.id] = filter.value;
        });

      return axios.get(config.apiHost + `cache`, {
        params: {
          "orderBy": orderBy,
          "orderDesc": orderDesc,
          "pageSize": pageSize,
          "filter": JSON.stringify(filters),
          "page": page
        }
      })
      .then(response => resolve(response.data))
      .catch(error => {
        if (error.response?.status === 401) {
          logout();
        }

        return reject(error);
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({loading: true});

    state = state || {
      pageSize: 100,
      page: 0,
      sorted: null,
      filtered: null
    };

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(
      state.pageSize || 100,
      state.page || 0,
      state.sorted || null,
      state.filtered || null
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        proxies: res.proxies,
        loading: false
      });
    });
  }

  change(name, stateBefore) {
    return axios.get(config.apiHost + `tasks/modify`, {
      params: {
        "task": name,
        "status": !stateBefore
      }
    })
    .then(() => {
      this.fetchData();
    })
    .catch(error => {
      alert(error.message);
      this.fetchData();
    });
  }

  changeSwitch(name, stateBefore, type) {
    let params = {"name": name.replace("cache.", "")};
    params[type] = !stateBefore;

    return axios.get(config.apiHost + `suppliers/set`, {
      params: params
    })
    .then(() => {
      this.fetchData();
    })
    .catch(error => {
      alert(error.message);
      this.fetchData();
    });
  }

  showLog(name) {
    this.setState({
      modalOpen: true,
      logText: null
    });

    return axios.get(config.apiHost + `tasks/log`, {
      params: {
        "task": name
      }
    })
    .then((response) => {
      this.setState({
        modalOpen: true,
        logText: response.data.text
      });
    })
    .catch(error => {
      this.setState({
        modalOpen: false,
        logText: null
      });
      alert(error.message);
    });
  }

  changeTimePeriod(name, value) {
    return axios.get(config.apiHost + `tasks/modify`, {
      params: {
        "task": name,
        "period": value
      }
    })
    .then(() => {
      this.fetchData();
    })
    .catch(error => {
      alert(error.message);
      this.fetchData();
    });
  }

  action(name: string, action: string) {
    this.setState({
      modalOpen: true,
      logText: null
    });

    return axios.get(config.apiHost + `tasks/` + action, {
      params: {
        "task": name
      }
    })
    .then(() => {
      this.setState({
        modalOpen: false
      });
      this.fetchData();
    })
    .catch(error => {
      this.setState({
        modalOpen: false
      });
      alert(error.message);
      this.fetchData();
    });
  }

  changeProxy(name, type, value) {
    let params = {
      "name": name.replace("cache.", ""),
      "type": type,
      "value": value
    };

    return axios.get(config.apiHost + `suppliers/proxy`, {
      params: params
    })
    .then(() => {
      this.fetchData();
    })
    .catch(error => {
      alert(error.message);
      this.fetchData();
    });
  }

  renderAuth() {
    const options = this.state.proxies.map(function (x) {
      return <option>{x}</option>;
    });

    const columns = [
      {
        Header: 'Id',
        accessor: 'id',
        maxWidth: 40
      },
      {
        Header: 'Name',
        id: 'task',
        accessor: r => r.name,
        Cell: row => (
          row.original['supplier'] == null ? <div>{row.original['name']}</div> :
            <div>{row.original['name']}<br/><code>{row.original['supplier']['type']}</code></div>
        ),
        maxWidth: 150
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 50,
        Cell: row => (
          <div align="center">
            <Checkbox checked={row.value === 'active'}
                      onChange={this.change.bind(this, row.original['name'], row.value === 'active')}/>
          </div>
        )
      },
      {
        Header: 'Switches',
        accessor: 'switches',
        maxWidth: 100,
        Cell: row => (
          <div align="left">
            <Checkbox checked={row.original['switches']['enabled']}
                      onChange={this.changeSwitch.bind(this, row.original['name'], row.original['switches']['enabled'], "search")}/> Search<br/>
            <Checkbox checked={row.original['switches']['validation']}
                      onChange={this.changeSwitch.bind(this, row.original['name'], row.original['switches']['validation'], "validation")}/> Validation<br/>
            <Checkbox checked={row.original['switches']['cache']}
                      onChange={this.changeSwitch.bind(this, row.original['name'], row.original['switches']['cache'], "cache")}/> Cache
          </div>
        )
      },
      {
        Header: 'Proxies',
        accessor: 'proxies',
        maxWidth: 150,
        Cell: row => (
          <div align="left" className="proxies">
            <select
              defaultValue={_.get(row.original, 'proxies.search', "")}
              onChange={e => this.changeProxy(row.original['name'], "search", e.target.value)}
            >{options}</select>
            <br/>
            <select
              defaultValue={_.get(row.original, 'proxies.validate', "")}
              onChange={e => this.changeProxy(row.original['name'], "validate", e.target.value)}
            >{options}</select>
            <br/>
            <select
              defaultValue={_.get(row.original, 'proxies.book', "")}
              onChange={e => this.changeProxy(row.original['name'], "book", e.target.value)}
            >{options}</select>
          </div>
        )
      },
      {
        Header: 'Period',
        accessor: 'period',
        maxWidth: 60,
        Cell: row => (
          <span>
            <input type="text" defaultValue={row.value} style={{border: '1px solid #F0F0F0', width: 40}}
                   onBlur={e => this.changeTimePeriod(row.original['name'], e.target.value)}/><br/>
            {row.original['maxRunTime'] && row.original['maxRunTime'] > 0 ?
              <span>Max run time: {row.original['maxRunTime']}</span> : ""}
          </span>
        )
      },
      {
        id: 'runStatus',
        Header: 'Last run',
        accessor: r => _.get(r, 'lastRun.status'),
        maxWidth: 170,
        Cell: row => {
          if (row.original['lastRun'] == null)
            return "Never launched";

          let lastRun = row.original['lastRun'];

          return <div>
            Status: {formatLabel(lastRun['status'])}<br/>
            Last: {lastRun['launchTime']}
          </div>
        }
      },
      {
        Header: 'Progress',
        accessor: 'status',
        maxWidth: 130,
        Cell: row => {
          if (row.original['lastRun'] == null)
            return "";

          if (row.original['lastRun']['total'] === 0)
            return "Progress: " + row.original['lastRun']['progress'];

          let p = row.original['lastRun']['progress'] * 100 / row.original['lastRun']['total'];

          return <div>
            <div className="progressContainer">
              <div
                className="progressBar"
                style={{
                  width: `${p}%`
                }}
              />
            </div>
            <div>
              <small>{row.original['lastRun']['progress']} / {row.original['lastRun']['total']}
                <b> ({Math.round(p * 10) / 10}%)</b></small>
            </div>
          </div>
        }
      },
      {
        Header: 'Success',
        id: 'success',
        width: 80,
        Cell: row => {
          if (row.original['lastRun'] == null || row.original['lastRun']['success'] === 0)
            return "";

          let p = row.original['lastRun']['success'] * 100 / row.original['lastRun']['progress'];

          return <div className="success">
            <div className="circle"
                 style={{backgroundColor: this.getColorForPercentage(p)}}
            />
            <span>
            {p.toFixed(1)}%
            </span>
          </div>
        }
      },
      {
        Header: 'Results',
        id: 'results',
        width: 90,
        Cell: row => {
          if (row.original['lastRun'] == null)
            return "";

          let p = row.original['lastRun']['results'] / row.original['lastRun']['progress'];

          return <div>
            <div style={{fontSize: "12px"}}>Results: {row.original['lastRun']['results']}</div>
            <div style={{fontSize: "12px"}}>RPS: {p.toFixed(1)}</div>
            <span>
                <a href="#" onClick={this.showLog.bind(this, row.original['name'])}>Show log</a>
            </span>
          </div>;
        }
      },
      {
        Header: '',
        width: 80,
        Cell: row => (
          <div className="btns">
            {
              row.original['kill'] ? <Label color='red' horizontal>Kill trigger set</Label> :
                row.original['launch'] ? <Label color='green' horizontal>Launch trigger set</Label> :
                  <div>
                    {
                      row.original['lastRun'] == null || row.original['lastRun']["status"] !== "started" ? (<span>
              <a href="#" onClick={this.action.bind(this, row.original['name'], 'launch')}><Icon
                name='play'/>Launch</a>
              </span>) : ""
                    }
                    {
                      row.original['lastRun'] != null && row.original['lastRun']["status"] === "started" ? (<span>
              <a href="#" onClick={this.action.bind(this, row.original['name'], 'kill')}><Icon name='remove'/>Kill</a>
              </span>) : ""
                    }
                  </div>
            }
          </div>
        )
      }
    ];

    const {data, pages, loading} = this.state;
    return (
      <div>
        <ReactTable
          getTrProps={(state, rowInfo, column) => {
            try {
              return {
                className: rowInfo.row.status === 'active' ? "greenRow" : "redRow"
              };
            } catch (e) {
              return "greenRow";
            }
          }}
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          filterable
          defaultPageSize={100}
          className="-highlight"
        />
        <Modal
          closeIcon={this.state.logText != null}
          open={this.state.modalOpen}
          basic={this.state.logText == null}
          onClose={() => this.setState({modalOpen: false})}
          size='small'
        >
          <Modal.Content>
            {this.state.logText ? <textarea>{this.state.logText}</textarea> : <Loader/>}
          </Modal.Content>
          {this.state.logText ? <Modal.Actions>
            <Button primary onClick={() => this.setState({modalOpen: false})}>
              <Icon name='remove'/> Close
            </Button>
          </Modal.Actions> : []
          }
        </Modal>
      </div>
    )
  }

  percentColors = [
    {pct: 0, color: {r: 0xff, g: 0x00, b: 0}},
    {pct: 70, color: {r: 0xff, g: 0x20, b: 0}},
    {pct: 80, color: {r: 0x80, g: 0x20, b: 0}},
    {pct: 90, color: {r: 0xa0, g: 0x80, b: 0}},
    {pct: 100, color: {r: 0x00, g: 0xff, b: 0}}
  ];

  getColorForPercentage(pct) {
    let i = 1;
    while (i < this.percentColors.length - 1) {
      if (pct < this.percentColors[i].pct)
        break;
      i++
    }
    const lower = this.percentColors[i - 1];
    const upper = this.percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }

}

export default CachePage;
