import '../css/booking.scss';

import React from 'react';
import Page from "./page";
import 'react-table-v6/react-table.css';
import {Link} from "react-router-dom";
import {logout} from "../utils/auth";
import axios from 'axios';
import qs from 'qs';
import {
  Accordion,
  Breadcrumb,
  Button,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Feed,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Loader,
  Message,
  Modal,
  Segment,
  Tab,
  Table
} from "semantic-ui-react";
import _ from "lodash";
import config from '../components/config';
import {formatLabel} from "../utils/utils";
import PricesButton from "../components/prices";
import ReactTable from "react-table-v6";
import AviasalesButton from "../components/aviasales";
import moment from "moment";

class BookingPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      data: null,
      logsOpen: false,
      flightsModalOpen: false,
      paxModalOpen: false,
      emailModalOpen: false,
      modalOpen: false,
      transactionAmount: '',
      transactionComment: '',

      emailModalLoading: false,
      emailFormTo: "",
      emailFormSubject: "",
      emailFormText: "",
      emailReplyToId: ""
    };
    this.loadData();
    this.renderEditableFlight = this.renderEditableFlight.bind(this);
    this.renderEditablePax = this.renderEditablePax.bind(this);

    setInterval(function (obj) {
        try {
          if (this.state.data.status === "waiting")
            obj.loadData();
        } catch (err) {
        }
      },
      10000,
      this
    );
  }

  loadData() {
    return axios.get(config.apiHost + `booking/` + this.getId())
    .then(response => {
      if (response.data.error !== "")
        return this.setState({error: response.data.error, loading: false, data: null});

      return this.setState({error: null, loading: false, data: response.data.data});
    })
    .catch(error => {
      if (error.response?.status === 401) {
        logout();
      }

      this.setState({error: error.message})
    });
  }

  getId() {
    return this.props.match.params.id;
  }

  renderBreadcrumbs() {
    return <Container className="breadcrumbs">
      <Breadcrumb>
        <Breadcrumb.Section>Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section><Link to="/bookings">Bookings</Link></Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section active>{this.getId()}</Breadcrumb.Section>
      </Breadcrumb>
    </Container>
  }

  renderAuth() {
    if (this.state.error != null)
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment>
            <Icon color='red' name='exclamation triangle' size='huge'/><br/><br/>
            <strong>{this.state.error}</strong>
          </Segment>
        </Container>
      </div>;

    if (this.state.loading) {
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment loading><br/><br/></Segment>
        </Container>
      </div>
    }

    return <div>
      {this.renderBreadcrumbs()}
      {
        this.state.data.test &&
        <Message warning>
          <Message.Header>This is a test booking!</Message.Header>
        </Message>
      }
      <Container>
        <Header as='h1'>{this.state.data.number}</Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={5}>
              {this.renderMainInfo()}
            </Grid.Column>
            <Grid.Column width={11}>
              {this.renderPassengers()}
              {this.renderFlights()}
              {this.renderComments()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderPnrs()}
        <br/>
        {this.renderCards()}
        <br/>
        {this.renderCharges()}
        <br/>
        {this.renderTransactions()}
        <br/>
        {this.renderLogs()}

        <Modal open={this.state.modalOpen} basic size='small'>
          <Modal.Content>
            <Loader/>
            <Header as='h3' textAlign='center' inverted className="loaderHeader">
              Loading...
            </Header>
          </Modal.Content>
        </Modal>
      </Container>
      <br/>
    </div>
  }

  showLoader() {
    this.setState({modalOpen: true});
  }

  hideLoader() {
    this.setState({modalOpen: false});
  }

  closeCard(cardId) {
    if (window.confirm("Close card?") !== true)
      return;

    this.showLoader();

    axios.get(config.apiHost + `cards/close`, {
      params: {
        "cardId": cardId
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  modifyCard(cardId) {
    let amount = this.showCardModal().replace(",", ".").trim();
    if (amount === null)
      return;

    this.showLoader();

    axios.get(config.apiHost + `cards/modify`, {
      params: {
        "cardId": cardId,
        "amount": amount
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  createCard(pnrId, provider) {
    let amount = this.showCardModal().replace(",", ".").trim();
    if (amount === null)
      return;

    this.showLoader();

    axios.get(config.apiHost + `cards/create`, {
      params: {
        "pnrId": pnrId,
        "amount": amount,
        "cardType": provider
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  showCardModal(amount = "") {
    let result = prompt("Enter card amount", amount);
    if (result === null || result === "")
      return null;

    return result;
  }

  renderJsLogModel(log) {
    const text = log.text;
    const url = config.apiHost.replace("/admin/", "") + log.url;

    if (text === "screenshot")
      return <Modal scrolling trigger={<img className="screenshot" src={url} height={80}/>} basic size='small'
                    className="log screenshot">
        <Modal.Content image>
          <Image wrapped size='medium' src={url}/>
        </Modal.Content>
      </Modal>;

    return <Modal trigger={<Button>HTML</Button>} basic className="log html">
      <Modal.Content scrolling>
        <iframe src={url} width="100%" height="500"/>
      </Modal.Content>
    </Modal>
  }

  renderPnrCards(pnr) {
    return <div>
      <Table celled attached compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={8}>
              <div className="myheader">
                <Header>Virtual cards</Header>
                <Dropdown button icon='plus' primary size='mini'>
                  <Dropdown.Menu>
                    {/*<Dropdown.Item text='Enett' onClick={this.createCard.bind(this, pnr.id, "enett")}/>*/}
                    <Dropdown.Item text='Anytime'
                                   onClick={this.createCard.bind(this, pnr.id, "anytime")}/>
                    <Dropdown.Item text='Ixaris (cashback)'
                                   onClick={this.createCard.bind(this, pnr.id, "ixaris-cashback")}/>
                    <Dropdown.Item text='Ixaris (regular)'
                                   onClick={this.createCard.bind(this, pnr.id, "ixaris-regular")}/>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Supplier</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Auth</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            pnr.cards.map(c => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{c.id}</Table.Cell>
                <Table.Cell>{c.date}</Table.Cell>
                <Table.Cell>{c.supplier} / {c.type}<br/>{c.supplierId}</Table.Cell>
                <Table.Cell>
                  <div className="cardNumber">
                    {c.number.substr(0, 4)}<b>{c.number.substr(4, 4)}</b>{c.number.substr(8, 4)}<b>{c.number.substr(12, 4)}</b>
                  </div>
                  Valid till: {c.exp}<br/>
                  CVV: {c.cvv}<br/>
                  Cardholder: {c.holder}
                </Table.Cell>
                <Table.Cell>{c.amount} {c.currency}</Table.Cell>
                <Table.Cell>
                  Auth: {c.totalAuthorised}<br/>
                  Settled: {c.totalSettled}<br/>
                  Refunded: {c.totalRefunded}<br/>
                  <Modal closeIcon trigger={<nobr><a href="#">Total TXes: {c.transactions.length}</a></nobr>}
                         className="">
                    <Modal.Content scrolling>
                      <Table celled attached compact size="small">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>Amount, EUR</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Decline reason</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {
                            c.transactions.map(tx => {
                              return <Table.Row>
                                <Table.Cell>{tx.date}</Table.Cell>
                                <Table.Cell>{tx.amountTransactionCurrency} {tx.currency}</Table.Cell>
                                <Table.Cell>{tx.amount}</Table.Cell>
                                <Table.Cell>{tx.description}</Table.Cell>
                                <Table.Cell>{formatLabel(tx.status)}</Table.Cell>
                                <Table.Cell>{tx.declinedReason}</Table.Cell>
                              </Table.Row>
                            })
                          }
                        </Table.Body>
                      </Table>
                    </Modal.Content>
                  </Modal>

                </Table.Cell>
                <Table.Cell>{formatLabel(c.status)}</Table.Cell>
                <Table.Cell>
                  {c.status === "valid" && <div>
                    <a href="#" onClick={this.closeCard.bind(this, c.id)}>Close</a><br/>
                    <a href="#" onClick={this.modifyCard.bind(this, c.id)}>Modify</a>
                  </div>
                  }
                  <Modal closeIcon trigger={<nobr><a href="#">Show card</a></nobr>} className="">
                    <Modal.Content scrolling>
                      <img src={c.url} width="auto" alt="Card"/>
                    </Modal.Content>
                  </Modal>
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  sendEmail() {
    const pnrId = this.state.emailFormPnrId;
    const to = this.state.emailFormTo;
    const subject = this.state.emailFormSubject;
    const text = this.state.emailFormText;
    const replyToId = this.state.emailReplyToId;

    if (to == "" || subject == "" || text == "" || to == null || subject == null || text == null) {
      alert("All fields are required!");
      return;
    }

    const data = {
      to: to,
      subject: subject,
      text: text,
      replyToId: replyToId
    };

    this.setState({
      emailModalLoading: true
    });

    axios.request({
      url: config.apiHost + `pnr/email`,
      method: 'post',
      headers: {'content-type': 'application/x-www-form-urlencoded'},
      params: {
        "id": pnrId
      },
      data: qs.stringify(data)
    })
    .then(response => {
      if (response.data.error !== "") {
        alert(response.data.error);
        return;
      }

      this.setState({
        emailModalLoading: false,
        emailModalOpen: false
      });

      this.hideLoader();
      this.loadData();
      alert("Email sent");
    })
    .catch(error => {
      this.setState({
        emailModalLoading: false
      });
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  renderPnrEmails(pnr) {
    return <div>
      <Modal open={this.state.emailModalOpen}>
        <Header content='Send email'/>
        <Modal.Content>
          <Form loading={this.state.emailModalLoading}>
            <Form.Field>
              <label>Reply-to-id</label>
              <input
                value={this.state.emailReplyToId}
                required
                placeholder=""
                onChange={e => this.setState({emailReplyToId: e.target.value})}
              />
            </Form.Field>

            <Form.Field required>
              <label>To</label>
              <input
                type="email"
                value={this.state.emailFormTo}
                required
                placeholder="test@test.com"
                onChange={e => this.setState({emailFormTo: e.target.value})}
              />
            </Form.Field>
            <Form.Field required>
              <label>Subject</label>
              <input
                required
                value={this.state.emailFormSubject}
                min={5}
                onChange={e => this.setState({emailFormSubject: e.target.value})}
              />
            </Form.Field>
            <Form.TextArea
              label='Text'
              value={this.state.emailFormText}
              required
              onChange={e => this.setState({emailFormText: e.target.value})}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={x => this.setState({emailModalOpen: false})}>
            <Icon name='remove'/> Cancel
          </Button>
          <Button color='green' onClick={this.sendEmail.bind(this)}>
            <Icon name='checkmark'/> Send
          </Button>
        </Modal.Actions>
      </Modal>
      <Table celled attached compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={8}>
              <div className="myheader">
                <Header>Emails</Header>
                <Button
                  icon="mail"
                  primary
                  size='mini'
                  onClick={() => this.setState({emailModalOpen: true, emailFormPnrId: pnr.id})}
                />
              </div>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>From</Table.HeaderCell>
            <Table.HeaderCell>To</Table.HeaderCell>
            <Table.HeaderCell>Subject</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            pnr.emails.map(c => {
              return <Table.Row verticalAlign='top' className="emails-row">
                <Table.Cell>{c.datetime}</Table.Cell>
                <Table.Cell className="emails-email">{c.sender}</Table.Cell>
                <Table.Cell className="emails-email">{c.recipient}</Table.Cell>
                <Table.Cell><span style={{
                  fontWeight: c.important ? "600" : "200"
                }}>{c.subject}</span>{c.forward && <> (To forward)</>} {c.forwarded && <> (Forwarded)</>}</Table.Cell>
                <Table.Cell className="iframe-nopad">
                  {c.text !== "" &&
                  <Modal closeIcon trigger={<Button size='mini' basic>Text</Button>} className="">
                    <Modal.Content scrolling><textarea style={{
                      border: 0,
                      width: "100%",
                      minHeight: "400px"
                    }}>{c.text}</textarea></Modal.Content>
                  </Modal>}
                  {c.html !== "" &&
                  <Modal closeIcon trigger={<Button size='mini' basic>HTML</Button>} className="">
                    <Modal.Content scrolling>
                      <iframe srcDoc={c.html} style={{
                        border: 0,
                        width: "100%",
                        minHeight: "600px"
                      }}/>
                    </Modal.Content>
                  </Modal>
                  }
                  <Button size='mini'
                          onClick={e => {
                            this.setState({
                              emailFormSubject: "Re: " + c.subject,
                              emailFormTo: c.sender,
                              emailReplyToId: c.messageId,
                              emailModalOpen: true, emailFormPnrId: pnr.id
                            });
                          }}
                  >
                    RE
                  </Button>
                  <Button size='mini' onClick={this.forwardEmail.bind(this, c.id)}>
                    FWD
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <ul className="tiny-ul">
                    {
                      c.files.map(f => {
                        return <li><a
                          href={config.apiHost + "/emails/file?id=" + f.id + "&key=" + f.key}
                          target="_blank">{f.filename}</a></li>;
                      })
                    }
                  </ul>
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  forwardEmail(emailId) {
    this.showLoader();

    axios.get(config.apiHost + `emails/forward`, {
      params: {
        "id": emailId
      }
    })
    .then(response => {
      if (_.get(response, 'data.error'))
        alert(response.data.error);
      else
        alert("Email forwarded");

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  setPnrStatus(pnrId, status) {
    let locator = "";
    if (status === "Issued") {
      locator = window.prompt("Locator:", "");
      if (locator === null || locator === "")
        return;
    } else if (!window.confirm("Set status to " + status + "?"))
      return;

    axios.get(config.apiHost + `pnr/set-status`, {
      params: {
        "id": pnrId,
        "status": status,
        "locator": locator
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
      alert("Status set");
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  rebookPnr(pnrId, supplier) {
    this.showLoader();

    axios.get(config.apiHost + `pnr/rebook`, {
      params: {
        "id": pnrId,
        "supplier": supplier
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);
      else
        alert("Rebooked");

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  updatePnr(pnrId) {
    this.showLoader();

    axios.get(config.apiHost + `pnr/update`, {
      params: {
        "id": pnrId
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  issuePnr(pnrId) {
    this.showLoader();

    axios.get(config.apiHost + `pnr/issue`, {
      params: {
        "id": pnrId
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  retryPnr(pnrId) {
    this.showLoader();

    axios.get(config.apiHost + `pnr/retry`, {
      params: {
        "id": pnrId
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  resetEmail(pnrId) {
    this.showLoader();

    axios.get(config.apiHost + `pnr/reset-email`, {
      params: {
        "id": pnrId
      }
    })
    .then(response => {
      if (response.data.error !== "")
        alert(response.data.error);

      this.hideLoader();
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.hideLoader();
      this.loadData();
    });
  }

  renderPnr(pnr, pnrIndex) {
    const logsOpen = this.state[`jsLogsOpen_${pnrIndex}`];

    let manageButton = <span/>;
    if (pnr.manageUrl != null) {
      manageButton = <Button positive onClick={x => window.open(pnr.manageUrl, "_blank")}>Manage</Button>
    }

    let refundKeys = [
      'emailSent',
      'selfRequested',
      'customerRequested',
      'refundRequested',
      'refundReceived',
      'refundMade',
      'altRefund'
    ];

    return <Tab.Pane>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <Table size="small" fluid celled unstackable attached width="100%">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>Info</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="name">Id</Table.Cell>
                  <Table.Cell>{pnr.id}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Email</Table.Cell>
                  <Table.Cell>
                    {pnr.email}<br/>
                    <a style={{cursor: 'pointer'}} onClick={this.resetEmail.bind(this, pnr.id)}>Reset</a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Supplier</Table.Cell>
                  <Table.Cell>{pnr.supplier}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Supplier original</Table.Cell>
                  <Table.Cell>{pnr.supplierOriginal}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Supplier id</Table.Cell>
                  <Table.Cell>{pnr.supplierId}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Airline PNRs</Table.Cell>
                  <Table.Cell>{pnr.airlinePnrs.join("\n")}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Tickets</Table.Cell>
                  <Table.Cell>{pnr.ticketNumbers.join("\n")}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Price</Table.Cell>
                  <Table.Cell>{pnr.price.amount}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Suspicious</Table.Cell>
                  <Table.Cell>
                    {
                      pnr.suspicious && <Label color='red' horizontal>Yes</Label>
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Extra luggage</Table.Cell>
                  <Table.Cell>
                    {
                      pnr.purchasedLuggage &&
                      <span>
                    <Label color='red' horizontal>Luggage</Label>
                        {pnr.purchasedLuggage.code} for {pnr.purchasedLuggage.price.amount} {pnr.purchasedLuggage.price.currency}
                    </span>
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Status</Table.Cell>
                  <Table.Cell>
                    {formatLabel(pnr.status)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>Options</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.keys(pnr.options).map((name) => {
                  return <Table.Row>
                    <Table.Cell className="name">{name}</Table.Cell>
                    <Table.Cell>
                      <input type="text" value={pnr.options[name]} className="valueText"/>
                      {
                        name === "jsSignature" && <span>
                          <a
                            href={"http://js.farecloud.io/" + pnr.supplier + "/validate?signature=" + pnr.options[name]}
                            target="_blank">Validate</a>
                        </span>
                      }
                    </Table.Cell>
                  </Table.Row>
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={11} textAlign="left">
            <Dropdown button text='Set status'>
              <Dropdown.Menu>
                <Dropdown.Item label={{color: 'green', empty: true, circular: true}} text='Issued'
                               onClick={this.setPnrStatus.bind(this, pnr.id, "Issued")}/>
                <Dropdown.Item label={{color: 'red', empty: true, circular: true}} text='Failed'
                               onClick={this.setPnrStatus.bind(this, pnr.id, "Failed")}/>
                <Dropdown.Item label={{color: 'gray', empty: true, circular: true}} text='New'
                               onClick={this.setPnrStatus.bind(this, pnr.id, "New")}/>
                <Dropdown.Divider/>
                <Dropdown.Item label={{color: 'red', empty: true, circular: true}} text='TOTALLY Failed'
                               onClick={this.setPnrStatus.bind(this, pnr.id, "total_failed")}/>
                <Dropdown.Item label={{color: 'red', empty: true, circular: true}} text='CANCELLED'
                               onClick={this.setPnrStatus.bind(this, pnr.id, "cancelled")}/>
              </Dropdown.Menu>
            </Dropdown>
            <Button onClick={this.updatePnr.bind(this, pnr.id)}>Update</Button>
            {pnr.status === "new" &&
            <Button positive onClick={this.issuePnr.bind(this, pnr.id)}>Issue</Button>}
            {pnr["jsResult"] && (pnr.status === "failed") &&
            <Button positive onClick={this.retryPnr.bind(this, pnr.id)}>Retry</Button>}
            <PricesButton pnrId={pnr.id} onSelect={supplier => this.rebookPnr(pnr.id, supplier)}/>
            <AviasalesButton pnrId={pnr.id}/>
            {manageButton}
            {pnr.error &&
            <div>
              <Divider/>
              <div className="error-block">
                {pnr.error}
              </div>
            </div>}
            {pnr["refund"] && <div>
              <Divider/>
              <Table size="small" fluid celled unstackable attached width="100%">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={refundKeys.length + 1}>
                      Refund
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Id</Table.Cell>
                    {
                      refundKeys.map(name => {
                        return <Table.Cell className="name">{name}</Table.Cell>
                      })
                    }
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{pnr["refund"]["id"]}</Table.Cell>
                    {
                      refundKeys.map(name => {
                        return <Table.Cell>
                          <Checkbox
                            onChange={this.changeRefundField.bind(this, pnr["refund"]["id"], name, !pnr["refund"][name])}
                            checked={pnr["refund"][name]}
                          />
                        </Table.Cell>
                      })
                    }
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            }
            {pnr["jsResult"] && <div>
              <Divider/>
              <Table size="small" fluid celled unstackable attached width="100%">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={_.keys(pnr["jsResult"]).length}>JS
                      Result</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    {
                      _.keys(pnr["jsResult"]).map(name => {
                        return <Table.Cell className="name">{name}</Table.Cell>
                      })
                    }
                  </Table.Row>
                  <Table.Row>
                    {
                      _.keys(pnr["jsResult"]).map(name => {
                        return <Table.Cell>{JSON.stringify(pnr["jsResult"][name])}</Table.Cell>
                      })
                    }
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            {pnr.jsLogs && pnr.jsLogs.length > 0 &&
            <div>
              <Divider/>
              <Accordion styled fluid className="jsLogs">
                <Accordion.Title active={logsOpen}
                                 onClick={x => this.setState({["jsLogsOpen_" + pnrIndex]: !logsOpen})}>
                  <Icon name='dropdown'/>
                  Show logs ({pnr.jsLogs.length})
                </Accordion.Title>
                <Accordion.Content active={logsOpen}>
                  <Feed>
                    {pnr.jsLogs.map(log => {
                      return <Feed.Event>
                        <div className="flexer">
                          <div className="time">{log.time}</div>
                          <div
                            className="text">{log.text === "screenshot" || log.text === "html" ? this.renderJsLogModel(log) : log.text}</div>
                        </div>
                      </Feed.Event>
                    })}
                  </Feed>
                </Accordion.Content>
              </Accordion>
            </div>
            }
            <Divider/>
            {this.renderPnrEmails(pnr)}
            <Divider/>
            {this.renderPnrCards(pnr)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  }

  renderPnrs() {
    let panes = this.state.data.pnrs.map((pnr, index) => {
      return {menuItem: 'Pnr #' + (index + 1), render: () => this.renderPnr(pnr, index)}
    });
    return <div>
      <br/>
      <Tab menu={{secondary: true, pointing: true}} panes={panes}/>
    </div>
  }

  renderEditableFlight(cellInfo) {
    return (
      <div
        style={{backgroundColor: "#fafafa"}}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = this.state.newFlights;
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;

          this.setState({newFlights: data});
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.newFlights[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  renderEditablePax(cellInfo) {
    return (
      <div
        style={{backgroundColor: "#fafafa"}}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = this.state.newPax;
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;

          this.setState({newPax: data});
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.newPax[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  }

  saveNewFlights() {
    this.setState({loading: true});

    return axios.post(config.apiHost + `booking/` + this.state.data.id + `/flights-change`,
      JSON.stringify(this.state.newFlights)
    )
    .then(() => {
      this.setState({flightsModalOpen: false});
      this.loadData();
      alert("Flights saved");
    })
    .catch(error => {
      alert(error.message);
      this.loadData();
    });
  }

  saveNewPax() {
    this.setState({loading: true});

    return axios.post(config.apiHost + `booking/` + this.state.data.id + `/pax-change`,
      JSON.stringify(this.state.newPax)
    )
    .then(() => {
      this.setState({paxModalOpen: false});
      this.loadData();
      alert("Passengers saved");
    })
    .catch(error => {
      alert(error.message);
      this.loadData();
    });
  }

  addComment() {
    let comment = window.prompt("Comment:", "");
    if (comment === null || comment === "")
      return;

    this.setState({loading: true});

    return axios.post(
      config.apiHost + `booking/` + this.state.data.id + `/add-comment`,
      comment
    )
    .then(() => {
      this.loadData();
    })
    .catch(error => {
      alert(error.message);
      this.loadData();
    });
  }

  changeRefundField(id, name, checked) {
    this.setState({loading: true});
    let self = this;

    return axios.get(
      config.apiHost + `refunds/change`,
      {
        params: {
          "id": id,
          "name": name,
          "value": checked
        }
      }
    )
    .then(() => {
      self.loadData();
    })
    .catch(error => {
      alert(error.message);
      self.loadData();
    });
  }

  renderComments() {
    return <div>
      <br/>
      <Button size='tiny' basic onClick={this.addComment.bind(this)}>Add comment</Button><br/>
      {this.state.data.comments.map(comment => {
        return <Message size='tiny' warning>
          <strong>{comment.author}</strong> at <strong>{comment.createdAt}</strong>
          <p>
            {comment.text}
          </p>
        </Message>
      })}
    </div>;
  }

  renderFlights() {
    return <div>
      <Modal open={this.state.flightsModalOpen}>
        <Header content='Edit flights'/>
        <Modal.Content>
          <ReactTable
            manual
            data={this.state.newFlights}
            columns={[
              {
                Header: "Pnr",
                accessor: "pnrIndex",
                Cell: this.renderEditableFlight,
                maxWidth: 50
              },
              {
                Header: "Segment",
                accessor: "segmentIndex",
                Cell: this.renderEditableFlight,
                maxWidth: 50
              },
              {
                Header: "MC",
                accessor: "marketingCarrier",
                Cell: this.renderEditableFlight,
                maxWidth: 50
              },
              {
                Header: "OC",
                accessor: "operatingCarrier",
                Cell: this.renderEditableFlight,
                maxWidth: 50
              },
              {
                Header: "Number",
                accessor: "number",
                Cell: this.renderEditableFlight,
                maxWidth: 90
              },
              {
                Header: "From",
                accessor: "from",
                Cell: this.renderEditableFlight,
                maxWidth: 70
              },
              {
                Header: "To",
                accessor: "to",
                Cell: this.renderEditableFlight,
                maxWidth: 70
              },
              {
                Header: "From Terminal",
                accessor: "departureTerminal",
                Cell: this.renderEditableFlight,
                maxWidth: 70
              },
              {
                Header: "To Terminal",
                accessor: "arrivalTerminal",
                Cell: this.renderEditableFlight,
                maxWidth: 70
              },
              {
                Header: "Departure",
                accessor: "departureDateTime",
                Cell: this.renderEditableFlight,
                maxWidth: 150
              },
              {
                Header: "Arrival",
                accessor: "arrivalDateTime",
                Cell: this.renderEditableFlight,
                maxWidth: 150
              },
              {
                Header: "Luggage",
                accessor: "includedLuggage",
                Cell: this.renderEditableFlight,
                maxWidth: 90
              }
            ]}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={x => this.setState({flightsModalOpen: false})}>
            <Icon name='remove'/> Cancel
          </Button>
          <Button color='green' onClick={this.saveNewFlights.bind(this)}>
            <Icon name='checkmark'/> Save
          </Button>
        </Modal.Actions>
      </Modal>
      <br/>
      <Message
        size="tiny"
        attached
        className="bg-light-green"
      >
        <Message.Header>
          Flights
          <Button className='message-right' primary size='mini'
                  onClick={x => this.setState({
                    flightsModalOpen: true,
                    newFlights: this.state.data.flights
                  })}>Change</Button>
        </Message.Header>
      </Message>
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Flight</Table.HeaderCell>
            <Table.HeaderCell>Departure</Table.HeaderCell>
            <Table.HeaderCell>Arrival</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.flights.map(flight => {
              let charter = "NO";
              if (flight.charter)
                charter = <Label color="red" key="red" size="mini">Yes</Label>;

              return <Table.Row verticalAlign='top'>
                <Table.Cell verticalAlign='top'>
                  {flight.fromFull.cityName} ({flight.fromFull.iata})
                  - {flight.toFull.cityName} ({flight.toFull.iata})<br/>
                  {flight.marketingCarrier} {flight.number}<br/>
                  Marketing carrier: {flight.marketingCarrierName}
                  {flight.operatingCarrier && flight.marketingCarrier !== flight.operatingCarrier &&
                  <span><br/>Operating carrier: {flight.operatingCarrier}</span>}
                  {flight.planeName && <span><br/>Plane: {flight.planeName}</span>}<br/>
                  <a
                    href={"https://www.aviasales.ru/search/" + flight.fromFull.iata + moment(flight.departureDateTime).format('DDMM') + flight.toFull.iata + this.state.data.passengers.length}
                    target="_blank">Aviasales</a> <a
                  href={"https://www.flightradar24.com/data/flights/" + flight.marketingCarrier.toLowerCase() + flight.number}
                  target="_blank">Radar</a>
                </Table.Cell>
                <Table.Cell>
                  {flight.departureDateTime}<br/>
                  Airport: {flight.fromFull.airportName}
                  {flight.departureTerminal && <span><br/>Terminal: {flight.departureTerminal}</span>}
                </Table.Cell>
                <Table.Cell>
                  {flight.arrivalDateTime}<br/>
                  Airport: {flight.toFull.airportName}
                  {flight.arrivalTerminal && <span><br/>Terminal: {flight.arrivalTerminal}</span>}
                </Table.Cell>
                <Table.Cell>
                  Free luggage: {flight.includedLuggage}<br/>
                  Segment index: {flight.segmentIndex}<br/>
                  Charter: {charter}
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderPassengers() {
    return <div>
      <Modal open={this.state.paxModalOpen}>
        <Header content='Edit passengers'/>
        <Modal.Content>
          <ReactTable
            manual
            data={this.state.newPax}
            columns={[
              {
                Header: "Type (A,C,I)",
                accessor: "type",
                maxWidth: 50
              },
              {
                Header: "Gender (M,F)",
                accessor: "sex",
                Cell: this.renderEditablePax,
                maxWidth: 50
              },
              {
                Header: "First name",
                accessor: "firstName",
                Cell: this.renderEditablePax,
                maxWidth: 50
              },
              {
                Header: "Last name",
                accessor: "lastName",
                Cell: this.renderEditablePax,
                maxWidth: 50
              },
              {
                Header: "Fathers name",
                accessor: "fName",
                Cell: this.renderEditablePax,
                maxWidth: 50
              },
              {
                Header: "Nationality",
                accessor: "nationality",
                Cell: this.renderEditablePax,
                maxWidth: 50
              },
              {
                Header: "DOB",
                accessor: "dateOfBirth",
                Cell: this.renderEditablePax,
                maxWidth: 50
              },
              {
                Header: "Passport",
                accessor: "passportNumber",
                Cell: this.renderEditablePax,
                maxWidth: 50
              },
              {
                Header: "Passport exp",
                accessor: "passportExpirationDate",
                Cell: this.renderEditablePax,
                maxWidth: 50
              }
            ]}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={x => this.setState({paxModalOpen: false})}>
            <Icon name='remove'/> Cancel
          </Button>
          <Button color='green' onClick={this.saveNewPax.bind(this)}>
            <Icon name='checkmark'/> Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Message
        size="tiny"
        attached
        className="bg-light-blue"
      >
        <Message.Header>
          Passengers
          <Button className='message-right' primary size='mini'
                  onClick={x => this.setState({
                    paxModalOpen: true,
                    newPax: this.state.data.passengers
                  })}>Change</Button>
        </Message.Header>
      </Message>
      <Table celled attached compact unstackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type, gender</Table.HeaderCell>
            <Table.HeaderCell>First name</Table.HeaderCell>
            <Table.HeaderCell>Last name</Table.HeaderCell>
            <Table.HeaderCell>F name</Table.HeaderCell>
            <Table.HeaderCell>DOB</Table.HeaderCell>
            <Table.HeaderCell>Nationality</Table.HeaderCell>
            <Table.HeaderCell>Passport</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.passengers.map(pax => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{pax.type === "A" ? "Adult" : (pax.type === "C" ? "Child" : "Infant")}, {pax.sex === "M" ? "Male" : "Female"}</Table.Cell>
                <Table.Cell>{pax.firstName}</Table.Cell>
                <Table.Cell>{pax.lastName}</Table.Cell>
                <Table.Cell>{pax.fName}</Table.Cell>
                <Table.Cell>{pax.dateOfBirth}</Table.Cell>
                <Table.Cell>{pax.nationality}</Table.Cell>
                <Table.Cell>
                  {pax.passportNumber}
                  {
                    pax.passportExpirationDate &&
                    <div>valid till {pax.passportExpirationDate}</div>
                  }
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderLogs() {
    const {logsOpen} = this.state;

    return <Accordion styled fluid className="logs">
      <Accordion.Title active={logsOpen} onClick={x => this.setState({logsOpen: !logsOpen})}>
        <Icon name='dropdown'/>
        Show logs ({this.state.data.log.length})
      </Accordion.Title>
      <Accordion.Content active={logsOpen}>
        <Feed>
          {this.state.data.log.map(log => {
            return <Feed.Event
              date={log.date + (log.pnr ? "PNR# " + log.pnr : "")}
              content={log.text}
            />
          })}
        </Feed>
      </Accordion.Content>
    </Accordion>
  }

  renderCards() {
    return <div>
      <Message
        size="tiny"
        attached
        header='Virtual cards'
        className='bg-light-blue'
      />
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Supplier</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.cards.map(c => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{c.id}</Table.Cell>
                <Table.Cell>{c.date}</Table.Cell>
                <Table.Cell>{c.supplier} / {c.supplierId}</Table.Cell>
                <Table.Cell>
                  <div className="cardNumber">
                    {c.number.substr(0, 4)}<b>{c.number.substr(4, 4)}</b>{c.number.substr(8, 4)}<b>{c.number.substr(12, 4)}</b>
                  </div>
                  Valid till: {c.exp}<br/>
                  CVV: {c.cvv}<br/>
                  Cardholder: {c.holder}
                </Table.Cell>
                <Table.Cell>{c.amount} {c.currency}</Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  addTransaction() {
    let amount = this.state.transactionAmount;
    let comment = this.state.transactionComment;

    if (amount === 0 || amount === '') {
      alert("No amount set");
      return;
    }

    if (comment === '') {
      alert("No comment set");
      return;
    }

    this.setState({loading: true});

    return axios.get(config.apiHost + `partner/` + this.state.data.partner + `/deposit`, {
      params: {
        "amount": -amount,
        "comment": comment,
        "bookingId": this.getId()
      }
    })
    .then(() => {
      this.loadData();
      alert("Transaction added");
    })
    .catch(error => {
      alert(error.message);
      this.loadData();
    });
  }

  removeTransaction(id) {
    if (!window.confirm("Are you sure you would like to remove transaction?"))
      return;

    this.setState({loading: true});

    return axios.get(config.apiHost + `partner/transactions/remove`, {
      params: {
        "id": id
      }
    })
    .then(() => {
      this.loadData();
      alert("Transaction removed");
    })
    .catch(error => {
      alert(error.message);
      this.loadData();
    });
  }

  renderTransactions() {
    return <div>
      <Message
        size="tiny"
        attached
        className='bg-light-red'
      >
        <div className="header">
          <div className="flex-header">
            <div className="title">Partner transactions</div>
            <div className="btn">
              <Modal trigger={<Button compact size='mini'>Add</Button>} closeIcon>
                <Header content='Add transaction'/>
                <Modal.Content>
                  <Form>
                    <Form.Field key="amount">
                      <label>Amount (EUR)</label>
                      <input placeholder='100'
                             value={this.state.transactionAmount}
                             onChange={e => this.setState({transactionAmount: e.target.value})}/>
                    </Form.Field>
                    <Form.Field key="description">
                      <label>Description</label>
                      <input placeholder='Transaction description...'
                             value={this.state.transactionComment}
                             onChange={e => this.setState({transactionComment: e.target.value})}/>
                    </Form.Field>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <Button color='red'>
                    <Icon name='remove'/> Cancel
                  </Button>
                  <Button color='green' onClick={this.addTransaction.bind(this)}>
                    <Icon name='checkmark'/> Add transaction
                  </Button>
                </Modal.Actions>
              </Modal>
            </div>
          </div>
        </div>
      </Message>
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Partner</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Comment</Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.transactions.map(t => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{t.partner}</Table.Cell>
                <Table.Cell>{t.date}</Table.Cell>
                <Table.Cell>{t.amount} {t.currency}</Table.Cell>
                <Table.Cell>{t.type}</Table.Cell>
                <Table.Cell>{t.comment}</Table.Cell>
                <Table.Cell width={1} textAlign="right">
                  <Button compact size='mini'
                          onClick={this.removeTransaction.bind(this, t.id)}>Remove</Button>
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderCharges() {
    return <div>
      <Message
        size="tiny"
        attached
        header='Charges'
        className='bg-light-green'
      />
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Pnr ID</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Provider</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.invoices.map(c => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{c.id}</Table.Cell>
                <Table.Cell>{c.pnrId}</Table.Cell>
                <Table.Cell>{c.date}</Table.Cell>
                <Table.Cell>{c.provider}<br/>{c.providerId}</Table.Cell>
                <Table.Cell>{c.amount} </Table.Cell>
                <Table.Cell>{c.description}</Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderMainInfo() {
    return <div>
      <Message
        size="tiny"
        attached
        className="bg-light-yellow"
        header='Main info'
      />
      <Table compact attached unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="name">Id</Table.Cell>
            <Table.Cell>{this.state.data.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Number</Table.Cell>
            <Table.Cell>{this.state.data.number}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Created at</Table.Cell>
            <Table.Cell>{this.state.data.createdAt}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Email</Table.Cell>
            <Table.Cell>
              <small>{this.state.data.email}</small>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Phone</Table.Cell>
            <Table.Cell>
              {this.state.data.phone}<br/>
              Phone country: {this.state.data.phoneCountry}, {this.state.data.phoneCountryCode}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Partner</Table.Cell>
            <Table.Cell>{this.state.data.partner}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Partner id</Table.Cell>
            <Table.Cell>
              {this.state.data.partnerId}
              {this.state.data.partnerId && this.state.data.partnerId.includes("-") && <><br/><a
                href={"https://admin2.jetcompass.com/booking/num-" + this.state.data.partnerId.slice(0, -2)}
                target="_blank">New</a></>}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Status</Table.Cell>
            <Table.Cell>{formatLabel(this.state.data.status)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Price original</Table.Cell>
            <Table.Cell>{this.state.data.priceOriginal}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Price last</Table.Cell>
            <Table.Cell>{this.state.data.priceLast}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Markup</Table.Cell>
            <Table.Cell>{this.state.data.markup}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  }
}

export default BookingPage;
