import React, {Component} from 'react'
import PropTypes from 'prop-types';
import axios from "axios";
import config from "./config";
import {Button, Dropdown} from "semantic-ui-react";

export default class PricesButton extends Component {

  state = {
    prices: {},
    loading: true,
    error: false
  };

  requestData() {
    return new Promise((resolve, reject) => {
      return axios.get(config.apiHost + `pnr/prices`, {
        params: {
          "id": this.props.pnrId
        }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
    });
  }

  fetchData() {
    this.setState({loading: true});

    this.requestData()
    .then(res => {
      this.setState({
        prices: res.prices,
        loading: false
      });
    })
    .catch(err => {
      this.setState({error: true, loading: false});
    });
  }

  render() {
    if (this.state.loading)
      return <Button loading>Loading</Button>;

    if (this.state.error)
      return <Button negative disabled>Prices load error</Button>;

    if (Object.keys(this.state.prices).length === 0)
      return <Button>No options</Button>;

    return <Dropdown button text='Rebook'>
      <Dropdown.Menu>
        {Object.keys(this.state.prices).map(name => {
          let priceObj = this.state.prices[name];
          let price = priceObj["base"]["amount"] + " " + priceObj["base"]["currency"];
          let text = name + " - " + price;
          if (priceObj["original"]["currency"] !== priceObj["base"]["currency"])
            text += " (" + priceObj["original"]["amount"] + " " + priceObj["original"]["currency"] + ")";
          if (priceObj["suspicious"])
            text = "S* " + text;
          return <Dropdown.Item key={name} text={text} onClick={e => this.props.onSelect(name)}/>
        })}
      </Dropdown.Menu>
    </Dropdown>
  }

  componentDidMount() {
    this.fetchData();
  }
}

PricesButton.propTypes = {
  pnrId: PropTypes.number,
  onSelect: PropTypes.func
};