import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

const render = (Component) =>
    ReactDOM.render(
        <Component/>,
        document.getElementById('root'),
    )

render(App)

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', () => { render(App) })
}
